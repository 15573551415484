/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style.scss";
// Replace above style with this css file to enable RTL styles
// @import "./assets/css/style.rtl";
@import "./assets/sass/plugins.scss";
// @import "./assets/css/style.rtl.css";
@import "./assets/sass/style.angular.scss";

// Keenicons - High quality and pixel perfect font icons available in 3 styles, duotone, outline and solid for Metronic elements
@import "./assets/plugins/keenicons/duotone/style.css";
@import "./assets/plugins/keenicons/outline/style.css";
@import "./assets/plugins/keenicons/solid/style.css";

@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

.angular-editor-toolbar {
  margin-top: 5px;
  background-color: #fff !important;
  border: 0px solid !important;
  border-radius: 10px !important;
  font-size: 12px !important;
}

.angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
  background-color: #fff !important;
  border: 0px solid !important;
  color: #1f1f1f !important;
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea {
  // min-height: calc(100vh - 420px) !important;
  // max-height: calc(100vh - 520px) !important;
  padding: 15px !important;
  border-radius: 10px !important;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  margin-top: 0;
  border: 1px solid #f2f7ff !important;
  background-color: #f5f5f5 !important;
  color: #1f1f1f !important;
}

.angular-editor-textarea:focus-visible {
  outline: 0px solid !important;
}

.ae-font .ae-picker-label {
  border: 0px solid #ddd !important;
}

.angular-editor .angular-editor-wrapper .angular-editor-textarea blockquote {
  // margin-left: 1rem;
  border: none !important;
  // padding-left: .5rem;
}
.pointer {
  cursor: pointer;
}
.bot-convo-bg {
  background-color: #e4eaef !important;
}
.fs-12 {
  font-size: 12px;
}

body.rtl {
  direction: rtl;
  text-align: right;
}

body.ltr {
  direction: ltr;
  text-align: left;
}
/* app.component.css */
html[dir="rtl"] {
  .app-sidebar-logo {
    .start-100 {
      left: -5px !important;
    }
  }
  /* Your styles for RTL direction */
  @media (min-width: 992px) {
    [data-kt-app-sidebar-fixed="true"] .app-wrapper {
      margin-left: 0px !important;
    }
  }

  @media (min-width: 992px) {
    [data-kt-app-header-fixed="true"][data-kt-app-sidebar-fixed="true"][data-kt-app-sidebar-push-header="true"]
      .app-header,
    [data-kt-app-header-fixed="true"][data-kt-app-sidebar-sticky="on"][data-kt-app-sidebar-push-header="true"]
      .app-header {
      left: 0px !important;
    }
  }
}

@media (min-width: 992px) {
  [data-kt-app-sidebar-fixed="true"] .app-wrapper {
    margin-left: calc(
      var(--bs-app-sidebar-width) + var(--bs-app-sidebar-gap-start, 0px) +
        var(--bs-app-sidebar-gap-end, 0px)
    );
  }

  @media (min-width: 992px) {
    [data-kt-app-header-fixed="true"][data-kt-app-sidebar-fixed="true"][data-kt-app-sidebar-push-header="true"]
      .app-header,
    [data-kt-app-header-fixed="true"][data-kt-app-sidebar-sticky="on"][data-kt-app-sidebar-push-header="true"]
      .app-header {
      left: calc(
        var(--bs-app-sidebar-width) + var(--bs-app-sidebar-gap-start, 0px) +
          var(--bs-app-sidebar-gap-end, 0px)
      );
    }
  }
}

html[dir="rtl"] {
  .app-sidebar {
    right: 0;
    left: auto;
  }

  .app-wrapper {
    .custom-app-content {
      margin-right: 260px;
      margin-left: 0;
    }
  }

  .accordion-button::after {
    margin-left: 0px !important;
    margin-right: auto !important;
  }

  .profile-action-btn {
    position: absolute;
    top: -8px;
    right: 40px;
    width: 55px;
  }
}

